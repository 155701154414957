import React from "react"
import HeaderV2 from "../headerv2/headerv2"
import Hero from "../../microcomponents/hero/hero"
import ClientLogos from "../../microcomponents/clientlogos/clientlogos"
import LeftRightImageText from "../../microcomponents/leftrightimagetext/leftrightimagetext"
import ReactCompareImage from "react-compare-image"
import { ArrowIcon, CompareSliderIcon } from "../../microcomponents/icons"
import Button from "../../microcomponents/button/button"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import ClientResults from "../../microcomponents/clientresults/clientresults"
import Image from "../../components/image-component/image-2023"
export default function HowItWorksV2() {
  const SliderComponent = () => {
    const leftSliderData = [
      {
        controlImage:
          "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/frame_1171275778.webp",
        variantImage:
          "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/frame_1171275777.webp",
        comparisonCaption: "",
      },
    ]

    return (
      <>
        {leftSliderData.map((item, index) => {
          return (
            <div className="slide-item slide-item-v2" key={index}>
              <ReactCompareImage
                handle={<CompareSliderIcon />}
                handleSize={64}
                sliderLineColor={"#1854F9"}
                sliderLineWidth={1}
                sliderPositionPercentage={0.73}
                leftImage={item.controlImage}
                rightImage={item.variantImage}
              />
              <div className="comparison-caption">{item.comparisonCaption}</div>
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div>
      <HeaderV2 version="v2" />
      <Hero
        version="v2"
        className="greeneclipse"
        breadcrumb={[
          { name: "Home", url: "/" },
          { name: "How it Works", url: null },
        ]}
        subHeading={null}
        heading="Get 3X more wins <br class='hideipad'/>by using <span>proven <br class='hideipad'/> winners.</span> Fast!"
        description="<p>The Prediction Engine finds proven <br class='hidedesktop hidetablet hideipad hidemobile'/> winning <br class='hidemobile'/> A/B tests. Our full-service team handles all  design <br class='hideipad hidemobile'/> and <br class='hidedesktop hidetablet hidemobile'/> dev to test those proven winners <br class='hideipad hidemobile'/> on your site.</p><p>Clients enjoy an average of 38% more<br class='hideipad hidemobile'/> conversions in <br class='hidetablet hidedesktop hidemobile'/> the first 90 days.</p>"
        tags={["Prediction Engine", "Full-Service", "Process", "Results"]}
        heroImage="frame_1171275358.webp"
        sourceFolder="spiralyze2024website"
        cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/v1710252838/spzinternal-website"
      />
      <ClientLogos version="v2" />
      <LeftRightImageText
        mainHeading="Know what wins. <br class='hidedesktop hidetablet hideipad'/> Based on data <br class='hidedesktop hidemobile'/> from 206,000 A/B tests."
        subHeading="Prediction Engine"
        // sectionDescription="<p>The prediction engine <span class='blue'>scrapes</span> the <br class='hidedesktop hidetablet hideipad'/> internet, finding A/B tests and <br class='hidemobile'/> tracking <br class='hidedesktop hidetablet hideipad'/> the outcomes. It captures <br class='hidetablet hideipad hidemobile'/> 8,000 new <br class='hidedesktop hidetablet hideipad'/> tests every month. <br class='hidedesktop hidemobile'/> So you harness the collective wisdom of all A/B tests.</p>"
        sectionDescription="<p>The <a class='blue' targe='_blank' href='/scraper/'>prediction engine scrapes A/B tests</a> across the internet and tracks the outcomes. It captures  8,000 new  tests every month. So you harness the collective wisdom of all A/B tests.</p>"
        innerClasses="pd-112"
        id="predictionengine"
        imageComponent={
          <>
            <SliderComponent />
          </>
        }
      >
        <Button
          version={"v2"}
          href="/prediction-engine/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Prediction Engine
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="Everything is <br class='hidedesktop hidetablet hideipad'/> included.<br class='hidedesktop hidemobile'/> Research, copy, design, & dev."
        subHeading="FULL SERVICE CRO AGENCY"
        // sectionDescription="<p>Our agency-style delivery model <br class='hidetablet hideipad'/> handles all the details for you. <br class='hidedesktop hidemobile'/> Get a dedicated team including research, copywriting, design, <br class='hidedesktop hidemobile'/> development, <br class='hidetablet hideipad hidemobile'/> QA, analytics, and project <br class='hidetablet hideipad hidemobile'/> management.</p><p>Your first test will be live two weeks after kickoff. A full pipeline of tests <br class='hidedesktop hidemobile'/> in two months.</p>"
        sectionDescription="<p>Our agency-style delivery model handles all the details for you. Get a dedicated team including research, copywriting, design, development, QA, analytics, and project management.</p><p>Your first test goes live two weeks after kickoff. Get a full pipeline of tests in two months.</p>"
        innerClasses="reverse pd-112"
        wrapperClasses="gray-bg"
        id="full-service"
        imageComponent={
          <div className="shadow">
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"false"}
              fallBackImage={`image-in-block_4.webp`}
              imgName={`image-in-block_4.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </div>
        }
      >
        <Button
          version={"v2"}
          href="/full-service-team/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Full-Service Team
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="Full roadmap. <br class='hidemobile'/> Evidence based iterative process."
        subHeading="Process"
        sectionDescription="<p>Get a full testing roadmap, with 3 <br class='hidetablet hideipad'/> months of tests, based on <br class='hidedesktop hidemobile'/> evidence <br class='hidetablet hideipad'/> from the prediction engine. Updated <br class='hidetablet hideipad'/> as new test data <br class='hidedesktop hidemobile'/> comes in. Maximize <br class='hidetablet hideipad'/> the testing potential from your traffic. <br class='hidedesktop'/> Minimize downtime between tests.</p>"
        innerClasses="pd-112"
        id="process"
        imageComponent={
          <div className="shadow">
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"false"}
              fallBackImage={`image-in-block_7.webp`}
              imgName={`image-in-block_7.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </div>
        }
      >
        <Button
          version={"v2"}
          href="/process/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Process
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="First win in 25 days. <br class=''/> 38% lift in 3 months. Guaranteed."
        subHeading="Fast Results"
        sectionDescription="<p>Combining proven winners from <br class='hidedesktop hidetablet hideipad'/> the prediction engine with the <br class='hidedesktop'/> fast <br class='hidetablet hideipad hidemobile'/> execution of the full-service <br class='hidedesktop hidetablet hideipad'/> team <br class='hidetablet hideipad hidemobile'/> gets you fast results.</p><p>We guarantee results with <a href='/pricing/' class='blue'>100% performance based fees.</a></p>"
        innerClasses="reverse pd-112"
        wrapperClasses="gray-bg"
        id="results"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"false"}
              fallBackImage={`frame_1171275773.webp`}
              imgName={`frame_1171275773.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          version={"v2"}
          href="/results/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Results
        </Button>
      </LeftRightImageText>
      <TopPredictions version="v2" />
      <ClientResults version="v2" />
    </div>
  )
}
